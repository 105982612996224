//= import baseline

//= import_tree plugins/

jQuery.extend(jQuery.expr.pseudos, {
  'all-focusable': function (el) {
    var
      hasTabindex = el.tabIndex !== undefined && +el.tabIndex > -1,
      map,
      mapName,
      nodeName = el.nodeName.toLowerCase(),
      returnValue = false;

    if ('a' === nodeName ) {
      returnValue = hasTabindex && el.href !== undefined && el.href !== '';
    } else if ('area' === nodeName) {
      map = el.parentNode;
      mapName = map.name;

      if (el.href && mapName && map.nodeName.toLowerCase() === 'map') {
        returnValue = $("img[usemap='#" + mapName + "']").length > 0;
      }
    } else if (/^(button|input|object|select|textarea)$/.test(nodeName)) {
      returnValue = true;
    } else {
      returnValue = hasTabindex;
    }

    return returnValue;
	}
});
jQuery.fn.extend((function () {
  var animateCssClasses = ['bounce', 'flash', 'pulse', 'rubberBand', 'shakeX', 'shakeY', 'headShake', 'swing', 'tada', 'wobble', 'jello', 'heartBeat', 'backInDown', 'backInLeft', 'backInRight', 'backInUp', 'backOutDown', 'backOutLeft', 'backOutRight', 'backOutUp', 'bounceIn', 'bounceInDown', 'bounceInLeft', 'bounceInRight', 'bounceInUp', 'bounceOut', 'bounceOutDown', 'bounceOutLeft', 'bounceOutRight', 'bounceOutUp', 'fadeIn', 'fadeInDown', 'fadeInDownBig', 'fadeInLeft', 'fadeInLeftBig', 'fadeInRight', 'fadeInRightBig', 'fadeInUp', 'fadeInUpBig', 'fadeInTopLeft', 'fadeInTopRight', 'fadeInBottomLeft', 'fadeInBottomRight', 'fadeOut', 'fadeOutDown', 'fadeOutDownBig', 'fadeOutLeft', 'fadeOutLeftBig', 'fadeOutRight', 'fadeOutRightBig', 'fadeOutUp', 'fadeOutUpBig', 'fadeOutTopLeft', 'fadeOutTopRight', 'fadeOutBottomRight', 'fadeOutBottomLeft', 'flip', 'flipInX', 'flipInY', 'flipOutX', 'flipOutY', 'lightSpeedInRight', 'lightSpeedInLeft', 'lightSpeedOutRight', 'lightSpeedOutLeft', 'rotateIn', 'rotateInDownLeft', 'rotateInDownRight', 'rotateInUpLeft', 'rotateInUpRight', 'rotateOut', 'rotateOutDownLeft', 'rotateOutDownRight', 'rotateOutUpLeft', 'rotateOutUpRight', 'hinge', 'jackInTheBox', 'rollIn', 'rollOut', 'zoomIn', 'zoomInDown', 'zoomInLeft', 'zoomInRight', 'zoomInUp', 'zoomOut', 'zoomOutDown', 'zoomOutLeft', 'zoomOutRight', 'zoomOutUp', 'slideInDown', 'slideInLeft', 'slideInRight', 'slideInUp', 'slideOutDown', 'slideOutLeft', 'slideOutRight', 'slideOutUp'].map(function (c) { return  'animate__' + c; });

  return {
    animateCss: function (animationClass) {
      if (!animationClass.split(' ').some(function(c) {
        return animateCssClasses.indexOf(c) >= 0;
      })) {
        return this; // if there's no animation class in the string, return
      }

      if (animationClass.indexOf('animate__animated') < 0) {
        animationClass += ' animate__animated';
      }

      return this.each(function () {
        var $el = $(this);

        $el
          .addClass(animationClass)
          .one('animationend', function () {
            $el.removeClass(animationClass);
          });
      });
    }
  };
}()));
jQuery.fn.extend({
  disclosure: function (disclosureOptions) {
    return this.each(function () {
      var
        $disclosure = $(this),
        options = $.extend({
          resetOnResize: true
        }, $disclosure.data(), disclosureOptions),
        $control;

      try {
        $control = $(options.control);
      } catch (e) {
        if (options.control.charAt(0) === '#') {
          $control = $(document.getElementById(options.control.substr(1)));
        }
      }

      function hide (event) {
        if (!$disclosure.hasClass('hiding')) {
          $disclosure
            .addClass('hiding')
            .trigger('disclosure:hiding', event);

          $control.addClass('disclosure-hiding');

          if (options.hidingAnimation !== undefined) {
            $disclosure
              .animateCss(options.hidingAnimation)
              .one('animationend', function () {
                finishHiding(event);
              });
          } else {
            finishHiding(event);
          }
        }
      }

      function finishHiding (event) {
        $disclosure
          .removeClass('shown')
          .addClass('hidden')
          .prop('hidden', true);

        $control
          .attr('aria-expanded', false)
          .removeClass('disclosure-hiding');

        $disclosure
          .removeClass('hiding')
          .trigger('disclosure:hidden', event);
      }

      function show (event) {
        if (!$disclosure.hasClass('showing')) {
          $disclosure
            .addClass('showing')
            .trigger('disclosure:showing', event);

          $control.addClass('disclosure-showing');

          $disclosure
            .removeClass('hidden')
            .addClass('shown')
            .prop('hidden', false);

          $control.attr('aria-expanded', true);

          if (options.showingAnimation !== undefined) {
            $disclosure
              .animateCss(options.showingAnimation)
              .one('animationend', function () {
                finishShowing(event);
              });
          } else {
            finishShowing(event);
          }
        }
      }

      function finishShowing (event) {
        $control.removeClass('disclosure-showing');

        $disclosure
          .removeClass('showing')
          .trigger('disclosure:showed', event);
      }

      $disclosure
        .id('disclosure') // for aria-controls
        .addClass('disclosure')
        .data('hidden', $disclosure.prop('hidden')) // for reset on resize
        .on('disclosure:show.baseline', show)
        .on('disclosure:hide.baseline', hide);

      $control
        .attr('aria-controls', (($control.attr('aria-controls') || '') + ' ' + $disclosure.attr('id')).trim())
        .attr('aria-expanded', $disclosure.is(':visible'))
        .on('click', function (event) { // show/hide on click
          $disclosure.is(':visible') ? hide(event) : show(event);
        });

      if (options.resetOnResize) { // reset on resize
        $(window).on('resize', function () {
          $disclosure
            .removeClass('hidden shown')
            .prop('hidden', $disclosure.data('hidden'));
          $control.attr('aria-expanded', $disclosure.is(':visible'));
        });
      }
    });
  }
});
jQuery.extend(jQuery.expr.pseudos, {
  focusable: function (el) {
    var
      $el = $(el),
      fieldset,
      hasTabindex = el.tabIndex !== undefined && +el.tabIndex > -1,
      map,
      mapName,
      nodeName = el.nodeName.toLowerCase(),
      returnValue = false;

    function parentsVisible ($el) {
      var visibility = $el.css('visibility');

      while (visibility === 'inherit') {
        $el = $el.parent();

        visibility = $el.css('visibility');
      }

      return visibility !== 'hidden';
    }

    if ('a' === nodeName ) {
      returnValue = hasTabindex && el.href !== undefined && el.href !== '';
    } else if ('area' === nodeName) {
      map = el.parentNode;
      mapName = map.name;

      if (el.href && mapName && map.nodeName.toLowerCase() === 'map') {
        returnValue = $("img[usemap='#" + mapName + "']").length > 0;
      }
    } else if (/^(button|input|object|select|textarea)$/.test(nodeName)) {
      returnValue = !el.disabled;

      if (returnValue) {
        fieldset = $el.closest('fieldset')[0];

        if (fieldset && !$el.closest('legend')[0]) {
          returnValue = !fieldset.disabled;
        }
      }
    } else {
      returnValue = hasTabindex;
    }

    return returnValue && $el.is(':visible') && parentsVisible($el);
	}
});
jQuery.fn.extend({
  hasEvent: function (event) {
    var namespace = '';

    event = event.split('.');

    if (event.length > 1) {
      namespace = event.pop();
      event = event.join('.');
    } else {
      event = event.pop();
    }

    try {
      return !!$._data(this[0], 'events')[event].find(function (delegate) {
        return (namespace === '' || namespace === delegate.namespace);
      });
    } catch (e) {
      return false;
    }
  }
});
jQuery.fn.extend({
  hasEventHandler: function (event, _function) {
    try {
      return this.hasEvent(event) &&
             !!$._data(this[0], 'events')[event.replace(/\.[^.]+$/, '')].find(function (delegate) {
               return _function.toString() === delegate.handler.toString();
             });
    } catch (e) {
      return false;
    }
  }
});
jQuery.fn.extend((function () {
  var idCounters = {};

  return {
    id: function (prefix) {
      prefix || (prefix = 'baseline');

      if (!idCounters[prefix]) {
        idCounters[prefix] = 0
      }

      return this.each(function () {
        if (typeof this.id !== 'string' || this.id === '') {
          this.id = '' + prefix + '_' + ++idCounters[prefix];
        }
      });
    }
  };
}()));
jQuery.fn.extend({
  menu: function (menuOptions) {
    return this.each(function () {
      var
        $menu = $(this),
        $menuItems = $menu.find(':all-focusable'),
        options = $.extend({
          resetOnResize: true
        }, $menu.data(), menuOptions),
        $control;

      try {
        $control = $(options.control);
      } catch (e) {
        if (options.control.charAt(0) === '#') {
          $control = $(document.getElementById(options.control.substr(1)));
        }
      }

      function close (event) {
        if (!$menu.hasClass('closing')) {
          $menu
            .addClass('closing')
            .trigger('menu:closing', event);

          $control.addClass('menu-closing');

          if (options.closingAnimation !== undefined) {
            $menu
              .animateCss(options.closingAnimation)
              .one('animationend', function () {
                finishClosing(event);
              });
          } else {
            finishClosing(event);
          }
        }
      }

      function finishClosing (event) {
        $menu
          .removeClass('opened')
          .addClass('closed')
          .prop('hidden', true);

        $control
          .attr('aria-expanded', false)
          .removeClass('menu-closing');

        $menu
          .removeClass('closing')
          .trigger('menu:closed', event);
      }

      function open (event) {
        if (!$menu.hasClass('opening')) {
          $menu
            .addClass('opening')
            .trigger('menu:opening', event);

          $control.addClass('menu-opening');

          $menu
            .removeClass('closed')
            .addClass('opened')
            .prop('hidden', false);

          $control.attr('aria-expanded', true);

          if (event.key === 'ArrowUp') {
            $menuItems[$menuItems.length - 1].focus();
          } else {
            $menuItems[0].focus();
          }

          if (options.openingAnimation !== undefined) {
            $menu
              .animateCss(options.openingAnimation)
              .one('animationend', function () {
                finishOpening(event);
              });
          } else {
            finishOpening(event);
          }
        }
      }

      function finishOpening (event) {
        $control.removeClass('menu-opening');

        $menu
          .removeClass('opening')
          .trigger('menu:opened', event);
      }

      $menuItems
        .attr('role', 'menuitem')
        .attr('tabindex', -1);

      $menu
        .id('menu') // for aria-controls
        .addClass('menu')
        .attr('role', 'menu')
        .data('hidden', $menu.prop('hidden')) // for reset on resize
        .on('menu:open.baseline', open)
        .on('menu:close.baseline', close)
        .on('keydown', function (event) { // move menu item focus or close on Tab
          var
            index = $menuItems.index($menuItems.filter(':focus')),
            key = event.key;

          if (key === 'ArrowUp' && index - 1 < 0) {
            key = 'End';
          } else if (key === 'ArrowDown' && index + 1 >= $menuItems.length) {
            key = 'Home';
          }

          switch (key) {
            case 'ArrowDown':
              $menuItems[index + 1].focus();
              event.preventDefault();
              break;
            case 'ArrowUp':
              $menuItems[index - 1].focus();
              event.preventDefault();
              break;
            case 'Home':
              $menuItems[0].focus();
              event.preventDefault();
              break;
            case 'End':
              $menuItems[$menuItems.length - 1].focus();
              event.preventDefault();
              break;
            case 'Tab':
              close(event);
          }
        });

      $control
        .attr('aria-controls', (($control.attr('aria-controls') || '') + ' ' + $menu.attr('id')).trim())
        .attr('aria-haspopup', true)
        .attr('aria-expanded', $menu.is(':visible'))
        .on('click', function (event) { // open/close on click
          $menu.is(':visible') ? close(event) : open(event);
        })
        .on('keydown', function (event) { // open on down arrow
          if ($menu.is(':hidden') && (event.key === 'ArrowDown' || event.key === 'ArrowUp')) {
            open(event);

            // don't scroll down
            event.preventDefault();
          }
        });

      if (options.resetOnResize) {
        $(window).on('resize', function () { // reset on resize
          $menu
            .removeClass('closed opened')
            .prop('hidden', $menu.data('hidden'));
          $control.attr('aria-expanded', $menu.is(':visible'));
        });
      }

      $(document)
        .on('click', function (event) { // close on non-menu click
          var $target = $(event.target);

          if ($menu.is(':visible') && !$target.is($control) && $target.closest($menu).length < 1) {
            close(event);
          }
        })
        .on('keydown', function (event) { // close on Esc
          if (event.key === 'Escape' && $menu.is(':visible')) {
            close(event);

            $control[0].focus();
          }
        });
    });
  }
});
jQuery.fn.extend({
  modal: function (modalOptions) {
    return this.each(function () {
      var
        $modal = $(this),
        options = $.extend({
          parent: 'body > div:first-of-type'
        }, $modal.data(), modalOptions),
        $control,
        $parent;

      try {
        $control = $(options.control);
      } catch (e) {
        if (options.control.charAt(0) === '#') {
          $control = $(document.getElementById(options.control.substr(1)));
        }
      }

      try {
        $parent = $(options.parent);
      } catch (e) {
        if (options.parent.charAt(0) === '#') {
          $parent = $(document.getElementById(options.parent.substr(1)));
        }
      }

      if ($parent.length < 1) {
        $parent = $('body');
      }

      function close (event) {
        if (!$modal.hasClass('closing')) {
          $modal
            .addClass('closing')
            .trigger('modal:closing', event);

          $control.addClass('modal-closing');

          if (options.closingAnimation !== undefined) {
            $modal
              .animateCss(options.closingAnimation)
              .one('animationend', function () {
                finishClosing(event);
              });
          } else {
            finishClosing(event);
          }
        }
      }

      function finishClosing (event) {
        $modal.prop('hidden', true);

        // allow focus again on non-modal elements
        $('[data-tabindex]')
          .each(function () {
            this.setAttribute('tabindex', eval(this.getAttribute('data-tabindex')));
            this.setAttribute('data-tabindex', null);
          });

        $parent.removeClass('modal-overlay');

        $modal.data('last-focus').focus();

        $control.removeClass('modal-closing');

        $modal
          .removeClass('closing')
          .trigger('modal:closed', event);
      }

      function open (event) {
        if (!$modal.hasClass('opening')) {
          $modal
            .addClass('opening')
            .trigger('modal:opening', event);

          $control.addClass('modal-opening');

          $modal.data('last-focus', document.activeElement);

          $parent.addClass('modal-overlay');

          // remove focus on non-modal elements
          $(':all-focusable')
            .not($(':all-focusable', $modal))
            .not('[tabindex^=-]')
            .each(function () {
              this.setAttribute('data-tabindex', this.getAttribute('tabindex'));
              this.setAttribute('tabindex', -1);
            });

          $modal.prop('hidden', false);

          if (options.openingAnimation !== undefined) {
            $modal
              .animateCss(options.openingAnimation)
              .one('animationend', function () {
                finishOpening(event);
              });
          } else {
            finishOpening(event);
          }
        }
      }

      function finishOpening (event) {
        $modal.find(':focusable')[0].focus();

        $control.removeClass('modal-opening');

        $modal
          .removeClass('opening')
          .trigger('modal:opened', event);
      }

      $modal
        .addClass('modal')
        .attr('aria-modal', true)
        .on('modal:open.baseline', open)
        .on('modal:close.baseline', close)
        .appendTo($parent) // move to the end of parent;

      $control.on('click', function (event) { // open/close on click
        $modal.is(':visible') ? close(event) : open(event);

        // don't follow links
        event.preventDefault();
      });

      $(document)
        .on('click', function (event) { // close on non-modal click
          var $target = $(event.target);

          // close on .close or overlay click
          if ($modal.is(':visible') && ($target.closest('.close').length > 0 || $target.is($parent))) {
            close(event);

            // don't follow links
            event.preventDefault();
          }
        })
        .on('keydown', function (event) { // close on Esc
          if (event.key === 'Escape' && $modal.is(':visible')) {
            close(event);
          }
        });
    });
  }
});

$(function () {
  $('body.no-js')
    .toggleClass('js no-js');

  $('a[href^="mailto:"]')
    .each(function () {
      var $a = $(this);

      $a
        .attr(
          'href',
          $a.attr('href')
            .replace(/\s*\(at\)\s*/ig, '@')
            .replace(/\s*\(dot\)\s*/ig, '.')
        )
        .html(
          $a.html()
            .replace(/\s*\(at\)\s*/ig, '@')
            .replace(/\s*\(dot\)\s*/ig, '.')
        );
    });
});
